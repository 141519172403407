import { AddIcon, BellIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Circle,
	Flex,
	HStack,
	IconButton,
	Img,
	Spacer,
	Text,
	Tooltip,
	useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { RoutePaths } from "../../services/route/route_paths";
import logo from "../../asset/logo.png";
import { NotificationsView } from "../notifications/notifications-view";
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";
import { useAuth } from "./auth-context";
import { ProfileAvatar } from "./profile-avatar";
import { GetFirstCharacterFromEmail, LogInfo } from "../../util/util";
import { ProfileSettingsView } from "../profile-setting/profile-setting-view";
import { BasicBackendNotificationApiService } from "../../services/backend/notifcation-api-service";
import { ThemeAppearance } from "../../appearance/appearance";
import { TextUnderlineView } from "./text-underline";

interface PageLayoutProps {
	children?: any;
	locName?: string;
}

interface SideDrawerItem {
	title: string;
	body: JSX.Element;
}

interface NotifcationIconProps {
	count: number;
}
const NotificationIcon = (props: NotifcationIconProps) => {
	if (props.count > 0) {
		return (
			<Box>
				<BellIcon position="relative" left="0px" top="10px" />
				<Circle
					position="relative"
					background="red"
					size="20px"
					left="10px"
					top="-20px"
				>
					<Text textColor="white" fontSize="xs">
						{props.count}
					</Text>
				</Circle>
			</Box>
		);
	} else {
		return (
			<Box>
				<BellIcon />
			</Box>
		);
	}
};

export const PageLayout = (props: PageLayoutProps) => {
	// const history = useHistory();
	const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
	const defaultSideDrawerValue: SideDrawerItem = {
		title: "",
		body: <div></div>,
	};
	const [sideDrawerItem, setSideDrawerItem] = useState(defaultSideDrawerValue);
	const [notificationsCount, setNotificationsCount] = useState(0);
	const { userCredential } = useAuth();
	const history = useHistory();

	// MEdia query
	const [isLargerThan1280] = useMediaQuery("(min-width: 400px)");

	// Effects
	useEffect(() => {
		new BasicBackendNotificationApiService(userCredential)
			.getNotificationsCount()
			.then((res) => {
				setNotificationsCount(res);
			})
			.catch((err) => {
				LogInfo("Error occured while trying to retrieve notification");
			});
	}, [userCredential]);

	// Handlers
	const handleOnDrawerClose = () => {
		setSideDrawerItem(defaultSideDrawerValue);
		setIsSideDrawerVisible(false);
	};

	// methods
	const doShowDrawerItem = (item: SideDrawerItem) => {
		setSideDrawerItem(item);
		setIsSideDrawerVisible(true);
	};

	const notificationButtonView = (
		<Tooltip size="md" hasArrow label="View Notifications" bg="blue.600">
			<IconButton
				aria-label="notification"
				variant="link"
				onClick={() => {
					// Display the notifcations as a side drawer.
					const item: SideDrawerItem = {
						title: "Notifications",
						body: <NotificationsView />,
					};
					doShowDrawerItem(item);
				}}
			>
				{/* <BellIcon />
				 */}
				<NotificationIcon count={notificationsCount} />
			</IconButton>
		</Tooltip>
	);
	const handleNewPost = () => {
		history.push(RoutePaths.createCodeSnippetRoute());
	};

	// Profile View
	const profileView = userCredential.user ? (
		<IconButton
			aria-label="Profile"
			variant="link"
			onClick={() => {
				const item: SideDrawerItem = {
					title: "",
					body: <ProfileSettingsView />,
				};
				doShowDrawerItem(item);
			}}
		>
			<ProfileAvatar
				fontSize="20px"
				size="40px"
				character={GetFirstCharacterFromEmail(userCredential.user!.email)}
			/>
		</IconButton>
	) : null;

	const toolbarView = (
		<HStack spacing={["10px", "15px"]}>
			<Button
				// colorScheme={ThemeAppearance.primaryColor}
				// background={ThemeAppearance.callToAction.button}
				borderColor={ThemeAppearance.callToAction.button}
				color={ThemeAppearance.callToAction.button}
				variant="outline"
				onClick={() => handleNewPost()}
				leftIcon={<AddIcon />}
			>
				New Snippet
			</Button>

			{notificationButtonView}
			{profileView}
		</HStack>
	);

	const mobileToolbarView = (
		<HStack spacing="20px">
			{notificationButtonView}
			{profileView}
		</HStack>
	);
	// <HStack>
	// 	{notificationButtonView}
	// 	<IconButton size="lg" aria-label="menu" onClick={() => {}} variant="link">
	// 		<HamburgerIcon />
	// 	</IconButton>
	// </HStack>

	return (
		<Box
			position="absolute"
			left="0"
			top="0"
			width="100vw"
			height="100vh"
			// background="yellow"
			display="flex"
			flexDirection="column"
		>
			{/* Navigation */}
			<Flex
				// position="fixed"
				background="white.100"
				padding="20px"
				shadow={[null, "md"]}
				direction="row"
				alignItems="center"
			>
				<HStack spacing="20px">
					<Link to={RoutePaths.codeSnippetListRoute()}>
						<Img src={logo} width="32px" height="32px" alt="ilearnswe logo" />
					</Link>
					<Link to={RoutePaths.codeSnippetListRoute()}>
						<TextUnderlineView
							text="Snippets"
							isActive={props.locName === "snippets"}
						/>
					</Link>
				</HStack>
				<Spacer />
				{isLargerThan1280 ? toolbarView : mobileToolbarView}
			</Flex>

			<Box height="90vh">{props.children}</Box>
			{/* Backdrop */}
			<Drawer
				isOpen={isSideDrawerVisible}
				placement="right"
				onClose={() => {
					handleOnDrawerClose();
				}}
				size={isLargerThan1280 ? "sm" : "sm"}
			>
				<DrawerOverlay>
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>{sideDrawerItem.title}</DrawerHeader>

						<DrawerBody>{sideDrawerItem.body}</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		</Box>
	);
};
