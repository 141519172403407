export class LanguageMode {
	static language = {
		python: "python",
		// cpp: "cpp",
		// java: "java",
		// swift: "swift",
		// text: "Text",
	};
	static modeDisplayName = {
		// [LanguageMode.language.text]: "Text",
		[LanguageMode.language.python]: "Python3",
		// [LanguageMode.language.cpp]: "C++",
		// [LanguageMode.language.java]: "Java",
		// [LanguageMode.language.swift]: "Swift",
	};

	static getModeDisplayName(mode: string) {
		return this.modeDisplayName[mode];
	}

	static all(): string[] {
		return [
			LanguageMode.language.python,
			// LanguageMode.language.cpp,
			// LanguageMode.language.java,
			// LanguageMode.language.swift,
			// LanguageMode.language.text,
		];
	}
}
