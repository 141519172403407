import {
	Modal,
	ModalOverlay,
	Text,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
} from "@chakra-ui/react";
import React from "react";

interface DisplayModalViewProps {
	isOpen: boolean;
	onClose: () => void;
	onAction1: () => void;
	onAction2: () => void;
	title: string;
	description: string;
	action1Text: string;
	action2Text: string;
}

const DisplayModalView = (props: DisplayModalViewProps) => {
	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{props.title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>{props.description}</Text>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="blue" mr={3} onClick={props.onAction1}>
						{props.action1Text}
					</Button>
					<Button colorScheme="red" variant="ghost" onClick={props.onAction2}>
						{props.action2Text}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

interface VerifyDeleteSnippetModalViewProps {
	isVisible: boolean;
	onDelete: () => void;
	onCancel: () => void;
}
export const VerifyDeleteSnippetModalView = (
	props: VerifyDeleteSnippetModalViewProps
) => {
	return (
		<DisplayModalView
			isOpen={props.isVisible}
			title={"Confirm Snippet Delete"}
			description={
				"Deleting this snippet removes it permanently. Do you want to continue?"
			}
			action1Text={"Cancel"}
			action2Text={"Delete"}
			onAction1={props.onCancel}
			onAction2={props.onDelete}
			onClose={props.onCancel}
		/>
	);
};

interface VerifyDiscardRevisionModalViewProps {
	isVisible: boolean;
	onCancel: () => void;
	onDiscard: () => void;
}

export const VerifyDiscardRevisionModalView = (
	props: VerifyDiscardRevisionModalViewProps
) => {
	return (
		<DisplayModalView
			isOpen={props.isVisible}
			title={"Confirm Revision Discard"}
			description={"Do you want to discard this new revision?"}
			action1Text={"Cancel"}
			action2Text={"Discard"}
			onAction1={props.onCancel}
			onAction2={props.onDiscard}
			onClose={props.onCancel}
		/>
	);
};
