import { CRCommentNotification } from "../../models/models";
import { LogInfo } from "../../util/util";
import { SortService } from "../lib/sort-filter-snippets";
import { AuthUserCredential } from "./auth-service";
import { FirebaseFunctionsService } from "./firebase-service";

interface CMCRCommentNotification {
	notificationId: string;
	notificationType: string;
	description: string;
	snippetId: string;
	revision: number;
	title: string;
	timestampMilli: number;
	activityCreatedTimestampMilli: number;
}

//  TODO: Others should be able to get your snippet too.
interface GetNotificationsResponse {
	notifications?: CMCRCommentNotification[]; // revision number.
}

interface MarkAsReadRequest {
	notificationId: string;
}

interface GetNotificationsCountResponse {
	count?: number; // revision number.
}

//  TODO: Others should be able to get your snippet too.
interface MarkAsReadResponse {
	status?: boolean; // revision number.
}

export interface BackendNotificationApiService {
	getNotificationsCount(): Promise<number>;
	getNotifications(): Promise<CRCommentNotification[]>;
	markNotificationAsRead(notificationId: string): Promise<boolean>;
}

export class BasicBackendNotificationApiService
	implements BackendNotificationApiService {
	userCredential: AuthUserCredential;

	constructor(userCredential: AuthUserCredential) {
		this.userCredential = userCredential;
	}

	getNotificationsCount(): Promise<number> {
		return new Promise((resolve, reject) => {
			const GetNotificationCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_NotificationService_GetNotificationsCount"
			);
			LogInfo("Sending GetNotifications request to server");
			GetNotificationCallable()
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetNotificationsCountResponse;
					const count = response.count ? response.count : 0;
					resolve(count);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getNotifications(): Promise<CRCommentNotification[]> {
		return new Promise((resolve, reject) => {
			const GetNotificationCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_NotificationService_GetNotifications"
			);
			LogInfo("Sending GetNotifications request to server");
			GetNotificationCallable()
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetNotificationsResponse;
					const responseData = response.notifications;
					const notificationsList: CRCommentNotification[] = [];
					if (responseData) {
						responseData.forEach((cmNotification) => {
							notificationsList.push({
								revision: cmNotification.revision,
								notificationType: cmNotification.notificationType,
								notificationId: cmNotification.notificationId,
								snippetId: cmNotification.snippetId,
								snippetTitle: cmNotification.title,
								dateTime: new Date(cmNotification.timestampMilli),
								description: cmNotification.description,
								activityCreatedDateTime: new Date(
									cmNotification.activityCreatedTimestampMilli
								),
							});
						});
					}
					// sort by date: from most recent to latest.
					// the most recent is the one with largest time.
					const sortedNotifcationList = new SortService((item: any) => {
						const snippet = item as CRCommentNotification;
						return snippet.activityCreatedDateTime.getMilliseconds();
					}).sort(notificationsList, false);

					resolve(sortedNotifcationList);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	markNotificationAsRead(notificationId: string): Promise<boolean> {
		const request: MarkAsReadRequest = { notificationId: notificationId };
		// Remove notification
		return new Promise((resolve, reject) => {
			const MarkAsReadCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_NotificationService_MarkAsRead"
			);
			LogInfo("Sending MarkAsRead request to server");
			MarkAsReadCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as MarkAsReadResponse;
					const status = response.status ? response.status : false;
					resolve(status);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
