export const ThemeAppearance = {
	primaryMainColor: "#03fc20", //"#1f6fff",
	primaryColor: "#1f6fff",
	cardBackground: "white.300",
	cardActiveColor: "blue.100",

	callToAction: {
		button: "#1f6fff", //"#19b009",
		danger: "#ff00ff",
	},
};
