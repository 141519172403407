import {
	Box,
	Flex,
	Heading,
	Text,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
	CodeReviewComment,
	CreateDefaultPost,
	PendingCodeReviewComment,
} from "../../models/models";
import {
	BasicBackendCodeSnippetApiService,
	CreateCodeRevisionRequest,
} from "../../services/backend/code-snippet-api-service";
import { GetUsernameFromEmail, LogError, LogInfo } from "../../util/util";
import { CodeEditorView, LineHighlight } from "./code-editor-view";
import { useAuth } from "../components/auth-context";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../../services/route/route_paths";
import {
	AddReviewsToSnippetRequest,
	BasicCodeReviewCommentApiService,
	GetSnippetReviewsRequest,
} from "../../services/backend/code-review-comment-api-service";
import { CodeReviewCommentsView } from "./review-comments-view";
import { ToolBarView } from "./tool-bar-view";
import {
	VerifyDeleteSnippetModalView,
	VerifyDiscardRevisionModalView,
} from "./verify-modal-view";
import { RevisionRequestModalView } from "./request-review-modal-view";
import { SpinnerBackdrop } from "../components/spinner-backdrop";
import { RevisionPaginationView } from "./revision-pagination-view";

interface CodeReviewViewProps {
	snippetId: string;
	revision?: number;
}

// const LoadingSpinner = (props: any) => {
// 	return (
// 		<Flex
// 			direction="column"
// 			justifyContent="start"
// 			alignItems="center"
// 			marginTop="20%"
// 			width="100%"
// 			height="100%"
// 		>
// 			<Spinner
// 				thickness="5px"
// 				speed="0.65s"
// 				emptyColor="gray.200"
// 				color="blue.200"
// 				size="xl"
// 				boxSize="100px"
// 			/>
// 		</Flex>
// 	);
// };

export const CodeReviewView = (props: CodeReviewViewProps) => {
	// Business Logic Related states
	const [docStatePost, setDocStatePost] = useState(CreateDefaultPost());
	const [docStatePostReviews, setDocStatePostReviews] = useState(
		new Array<CodeReviewComment>()
	);
	const defaultValue: PendingCodeReviewComment[] = [];
	const [
		docStatePendingReviewsState,
		setDocStatePendingReviewsState,
	] = useState(defaultValue);
	const [
		docStateNewRevisionCodeText,
		setDocStateNewRevisionCodeText,
	] = useState("");

	// UI Related States
	// Network Related UI States
	const [
		uiStateNetworkIsFetchingSnippet,
		setUiStateNetworkIsFetchingSnippet,
	] = useState(false);
	const [
		uiStateNetworkIsPostingNewRevision,
		setUiStateNetworkIsPostingNewRevision,
	] = useState(false);
	const [
		uiStateNetworkIsPostingReviews,
		setUiStateNetworkIsPostingReviews,
	] = useState(false);
	const [
		uiStateNetworkIsDeletingPost,
		setUiStateNetworkIsdeletingPost,
	] = useState(false);
	const [uiStateIsReviewVisibile, setUiStateIsReviewVisible] = useState(true);
	const [
		uiStateCurrentCursorPosition,
		setUiStateCurrentCursorPosition,
	] = useState({
		line: 1,
		column: 1,
	});
	const [
		uiStateActiveReviewCommentId,
		setUiStateActiveReviewCommentId,
	] = useState("");
	const [
		uiStateIsCreatingNewRevision,
		setUiStateIsCreatingNewRevision,
	] = useState(false);

	// modal item
	const [uiStateActiveModalItem, setUiStateActiveModalItem] = useState(
		<div></div>
	);
	const [
		uiStateIsActiveModalVisible,
		setUiStateIsActiveModalVisible,
	] = useState(false);

	// query
	const [uiStateIsLargeScreen] = useMediaQuery("(min-width: 400px)");

	const authState = useAuth();
	const history = useHistory();

	// Effects
	useEffect(() => {
		setUiStateNetworkIsFetchingSnippet(true);
		new BasicBackendCodeSnippetApiService(authState.userCredential)
			.getSnippetDetail({
				snippetId: props.snippetId,
				revision: props.revision,
			})
			.then((res) => {
				setDocStatePost(res);
				setUiStateNetworkIsFetchingSnippet(false);
			})
			.catch((err) => {
				LogError("error occured while retrieving post", err);
				setUiStateNetworkIsFetchingSnippet(false);
			});
	}, [authState.userCredential, props.snippetId, props.revision]);

	// Get Post Reviews
	useEffect(() => {
		const request: GetSnippetReviewsRequest = {
			snippetId: docStatePost.snippetId,
			revision: docStatePost.revision,
		};
		new BasicCodeReviewCommentApiService(authState.userCredential)
			.getSnippetReviews(request)
			.then((res) => {
				setDocStatePostReviews(res);
			})
			.catch((err) => {
				LogError("Error occured while retrieving post reviews", err);
			});
	}, [authState.userCredential, docStatePost.snippetId, docStatePost.revision]);

	// Other Hooks
	const toast = useToast();

	const doShowAsActiveModalItem = (item: JSX.Element) => {
		setUiStateActiveModalItem(item);
		setUiStateIsActiveModalVisible(true);
	};

	const doHideActiveModalItem = () => {
		setUiStateIsActiveModalVisible(false);
		setUiStateActiveModalItem(<div></div>);
	};

	// handlers
	const handleCopyCode = () => {
		toast({
			title: "Copied.",
			description: "Code copied to clipboard",
			status: "success",
			duration: 9000,
			isClosable: true,
		});
	};

	const handleReviewVisibilityChange = () => {
		setUiStateIsReviewVisible(!uiStateIsReviewVisibile);

		//
		setUiStateActiveReviewCommentId("");
	};

	const handleOnAnnotate = () => {
		const newValue: PendingCodeReviewComment = {
			lineStart: uiStateCurrentCursorPosition.line,
			lineStop: uiStateCurrentCursorPosition.line,
			reviewText: "",
			id: Date.now().toString(),
		};
		const newPendingReviews = [...docStatePendingReviewsState, newValue];
		setDocStatePendingReviewsState(newPendingReviews);
	};

	const handleCancelDeletePost = () => {
		doHideActiveModalItem();
	};
	const handleMaybeDeletePost = () => {
		const item = (
			<VerifyDeleteSnippetModalView
				isVisible={true}
				onDelete={handleDeletePost}
				onCancel={handleCancelDeletePost}
			/>
		);
		doShowAsActiveModalItem(item);
	};
	const handleDeletePost = () => {
		// Make request to server to remove post.
		// TODO: Can we use another state?
		//    this is used to just disable the screen while working.
		setUiStateNetworkIsdeletingPost(true);
		doHideActiveModalItem();
		new BasicBackendCodeSnippetApiService(authState.userCredential)
			.deleteSnippet(docStatePost.snippetId)
			.then((res) => {
				toast({
					title: "Post Removed",
					description: "Post Deleted Successfully",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				// setIsDeletingPost(false);
				history.push(RoutePaths.codeSnippetListRoute());
			})
			.catch((err) => {
				toast({
					title: "Post Remove Failed",
					description: "Failed to delete post",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				// setIsDeletingPost(false);
				setUiStateNetworkIsdeletingPost(false);
				doHideActiveModalItem();
			});
	};

	const handleOnNewRevision = () => {
		// Handles Request for new Revision.
		setUiStateIsCreatingNewRevision(true);
		setDocStateNewRevisionCodeText(docStatePost.codeText);
		// Enable the Code Editor. Leave the rest.
	};

	const handleRevisionTextChange = (codeText: string) => {
		setDocStateNewRevisionCodeText(codeText);
	};

	const handleCodeReviewCommentSelected = (value: CodeReviewComment) => {
		setUiStateActiveReviewCommentId(value.commentId);
	};
	const handlePendingCodeReviewCommentSelected = (
		value: PendingCodeReviewComment
	) => {
		setUiStateActiveReviewCommentId(value.id);
	};

	const handleOnRemovePendingReviewComment = (commentId: string) => {
		const newList: PendingCodeReviewComment[] = [];
		docStatePendingReviewsState.forEach((review) => {
			if (review.id !== commentId) {
				newList.push(review);
			}
		});
		setDocStatePendingReviewsState(newList);
	};

	// HandleOnUpdatePendingReviewComment
	const handleOnUpdatePendingReviewComment = (
		updatedValue: PendingCodeReviewComment
	) => {
		//
		const newList: PendingCodeReviewComment[] = [];
		docStatePendingReviewsState.forEach((review) => {
			if (review.id === updatedValue.id) {
				newList.push(updatedValue);
			} else {
				newList.push(review);
			}
		});
		setDocStatePendingReviewsState(newList);
	};

	// HandleOnSendPendingReviews
	const handleSendPendingReviews = () => {
		setUiStateNetworkIsPostingReviews(true);
		const request: AddReviewsToSnippetRequest = {
			snippetId: docStatePost.snippetId,
			reviews: docStatePendingReviewsState,
			revision: docStatePost.revision,
		};
		new BasicCodeReviewCommentApiService(authState.userCredential)
			.addReviewsToSnippet(request)
			.then((res) => {
				// set the new post
				toast({
					title: "Sent",
					description: "Reviews sent successfully",
					status: "success",
					duration: 9000,
					isClosable: true,
				});

				setUiStateNetworkIsPostingReviews(false);
				setDocStatePendingReviewsState([]);
				setDocStatePostReviews(res);
				//
			})
			.catch((err) => {
				setUiStateNetworkIsPostingReviews(false);
				toast({
					title: "Review Failed",
					description: "Failed to post reviews. please try again",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				LogError("Error Occured while posting reviews");
			});
	};

	const handleMaybeSendRevisionForReview = () => {
		// TODO: Send the New Revision for Review.
		// show modal to confirm send for review.
		const item = (
			<RevisionRequestModalView
				isVisible={true}
				onCancel={handleCancelSendRevisionForReview}
				onSend={(notes) => handleSendRevisionForReview(notes)}
			/>
		);
		doShowAsActiveModalItem(item);
	};
	const handleCancelSendRevisionForReview = () => {
		doHideActiveModalItem();
	};

	const handleSendRevisionForReview = (notes: string) => {
		// Make Api Call so send Revision.
		doHideActiveModalItem();
		setUiStateIsCreatingNewRevision(false);
		setUiStateNetworkIsPostingNewRevision(true);
		const request: CreateCodeRevisionRequest = {
			currentRevision: docStatePost.revision,
			snippetId: docStatePost.snippetId,
			codeText: docStateNewRevisionCodeText,
			notes: notes,
		};
		new BasicBackendCodeSnippetApiService(authState.userCredential)
			.createRevision(request)
			.then((res) => {
				setDocStatePost(res);
				// doHideActiveModalItem();
				// Then you are nolonger reviwing.
				// setIsCreatingNewRevision(false);
				setUiStateNetworkIsPostingNewRevision(false);

				toast({
					title: "Revision Sent",
					description: "Snippet has been updated and sent for review",
					status: "success",
					duration: 4000,
					isClosable: true,
				});
			})
			.catch((err) => {
				LogError("Error occured while trying to post. ");
				doHideActiveModalItem();
			});

		// hide modal.
	};

	const handleMaybeDiscardRevision = () => {
		// Maybe Discard Revision.
		const item = (
			<VerifyDiscardRevisionModalView
				isVisible={true}
				onDiscard={handleDiscardRevision}
				onCancel={handleCancelDiscardRevision}
			/>
		);
		doShowAsActiveModalItem(item);
	};

	const handleDiscardRevision = () => {
		// Discard the current revision.
		setUiStateIsCreatingNewRevision(false);
		setDocStateNewRevisionCodeText("");
		doHideActiveModalItem();
	};

	const handleCancelDiscardRevision = () => {
		// Close dialog
		doHideActiveModalItem();
	};

	const handleOnRevisionSelected = (revision: number) => {
		// navigate to that snippet
		LogInfo("Revision Number selected ", revision);
		history.push(
			RoutePaths.codeSnippetDetailWithRevisionRoute(props.snippetId, revision)
		);
	};

	const GetLinesToHighlight = (
		commentId: string
	): LineHighlight | undefined => {
		if (commentId) {
		}
		// TODO: Make this better
		// search existing
		const lineHighlight: LineHighlight = { lineStart: 0, lineStop: 0 };

		for (let review of docStatePostReviews) {
			if (review.commentId === commentId) {
				lineHighlight.lineStart = review.lineStart;
				lineHighlight.lineStop = review.lineStop;
				return lineHighlight;
			}
		}
		// search pending
		for (let review of docStatePendingReviewsState) {
			if (review.id === commentId) {
				lineHighlight.lineStart = review.lineStart;
				lineHighlight.lineStop = review.lineStop;
				return lineHighlight;
			}
		}
		// return linestoHighlight;
		return undefined;
	};

	const editorView = (
		<>
			<CodeEditorView
				lineHighlight={GetLinesToHighlight(uiStateActiveReviewCommentId)}
				readOnly={true}
				value={docStatePost.codeText}
				languageMode={docStatePost.languageMode}
				onCursorPositionChange={(value) =>
					setUiStateCurrentCursorPosition(value)
				}
				onTextChange={() => {}}
				name="code-editor-div"
				width="100%"
				height="100%"
			/>

			{uiStateIsCreatingNewRevision ? (
				<CodeEditorView
					lineHighlight={GetLinesToHighlight(uiStateActiveReviewCommentId)}
					readOnly={false}
					value={docStateNewRevisionCodeText}
					languageMode={docStatePost.languageMode}
					onCursorPositionChange={
						(value) => {}
						// setCurrentCursorPositionState(value)
					}
					onTextChange={handleRevisionTextChange}
					name="code-editor-div"
					width="100%"
					height="100%"
				/>
			) : null}
		</>
	);
	if (uiStateNetworkIsFetchingSnippet) {
		return <SpinnerBackdrop shortDescription="Retrieving snippet" />;
	} else if (uiStateNetworkIsDeletingPost) {
		return <SpinnerBackdrop shortDescription="Removing snippet" />;
	}

	const editorMobileView = (
		<>
			{uiStateIsReviewVisibile ? (
				// Review Pane
				<Box
					flexGrow={1}
					width="500px"
					// height="80vh"
					// background="orange"
					display="flex"
					flexDirection="column"
					alignItems="stretch"
					borderWidth="1px"
					overflowY="auto"
					paddingY="10px"
				>
					<CodeReviewCommentsView
						isPostingReviews={uiStateNetworkIsPostingReviews}
						activeReviewCommentId={uiStateActiveReviewCommentId}
						reviews={docStatePostReviews}
						pendingReviews={docStatePendingReviewsState}
						onCodeReviewCommentSelected={handleCodeReviewCommentSelected}
						onPendingCodeReviewCommentSelected={
							handlePendingCodeReviewCommentSelected
						}
						onUpdatePendingReviewComment={handleOnUpdatePendingReviewComment}
						onRemovePendingReviewComment={handleOnRemovePendingReviewComment}
						onSendPendingReviews={handleSendPendingReviews}
					/>
				</Box>
			) : (
				<Flex direction="row" width="100%" height="100%" flexGrow={0}>
					{editorView}
				</Flex>
			)}
		</>
	);
	const editorLargeScreenView = (
		<>
			<Flex direction="row" width="100%" height="100%" flexGrow={0}>
				{editorView}
			</Flex>

			{uiStateIsReviewVisibile ? (
				// Review Pane
				<Box
					flexGrow={1}
					width="600px"
					// height="88vh"
					// background="orange"
					display="flex"
					flexDirection="column"
					alignItems="stretch"
					borderWidth="1px"
					overflowY="auto"
					// padding="20px"
				>
					<CodeReviewCommentsView
						isPostingReviews={uiStateNetworkIsPostingReviews}
						activeReviewCommentId={uiStateActiveReviewCommentId}
						reviews={docStatePostReviews}
						pendingReviews={docStatePendingReviewsState}
						onCodeReviewCommentSelected={handleCodeReviewCommentSelected}
						onPendingCodeReviewCommentSelected={
							handlePendingCodeReviewCommentSelected
						}
						onUpdatePendingReviewComment={handleOnUpdatePendingReviewComment}
						onRemovePendingReviewComment={handleOnRemovePendingReviewComment}
						onSendPendingReviews={handleSendPendingReviews}
					/>
				</Box>
			) : null}
		</>
	);

	const ToolbarAndNameLargeScreenView = () => (
		<Flex
			direction="row"
			marginBottom="15px"
			justifyContent="center"
			alignItems="center"
			width="100%"
		>
			{/* PostHeader */}
			<Flex
				marginRight="1em"
				// maxWidth="300px"
				direction="column"
				alignItems="flex-start"
				// marginBottom="10px"
				width="100%"
				justifyContent="start"
			>
				<Heading
					size={uiStateIsLargeScreen ? "md" : "xs"}
					maxWidth={["200px", "400px"]}
				>
					{docStatePost.title}
				</Heading>
				<Text fontSize="xs">
					author: {GetUsernameFromEmail(docStatePost.authorName)}
				</Text>
			</Flex>
			{/* <Spacer /> */}

			{/* <Box width="50px" /> */}
			{/* Tool Bar */}
			<ToolBarView
				snippet={docStatePost}
				isCreatingNewRevision={uiStateIsCreatingNewRevision}
				isReviewVisible={uiStateIsReviewVisibile}
				isPostingNewRevision={uiStateNetworkIsPostingNewRevision}
				languageMode={docStatePost.languageMode}
				onCopyCode={handleCopyCode}
				onAnnotate={handleOnAnnotate}
				onReviewVisibilityChange={handleReviewVisibilityChange}
				onDeletePost={handleMaybeDeletePost}
				onNewRevision={handleOnNewRevision}
				onRequestReviewOfRevision={handleMaybeSendRevisionForReview}
				onDiscardRevision={handleMaybeDiscardRevision}
			/>
		</Flex>
	);

	const ToolbarAndNameMobileScreenView = () => (
		<Flex
			direction="column"
			marginBottom="15px"
			justifyContent="center"
			alignItems="center"
			width="100%"
		>
			{/* PostHeader */}
			<Flex
				direction="column"
				alignItems="center"
				marginBottom="10px"
				width="100%"
				justifyContent="start"
			>
				<Heading size={"xs"} maxWidth={["100%"]}>
					{docStatePost.title.substr(0, 200)}
				</Heading>
				<Text fontSize="xs">
					author: {GetUsernameFromEmail(docStatePost.authorName)}
				</Text>
			</Flex>
			{/* Tool Bar */}
			<ToolBarView
				snippet={docStatePost}
				isCreatingNewRevision={uiStateIsCreatingNewRevision}
				isReviewVisible={uiStateIsReviewVisibile}
				isPostingNewRevision={uiStateNetworkIsPostingNewRevision}
				languageMode={docStatePost.languageMode}
				onCopyCode={handleCopyCode}
				onAnnotate={handleOnAnnotate}
				onReviewVisibilityChange={handleReviewVisibilityChange}
				onDeletePost={handleMaybeDeletePost}
				onNewRevision={handleOnNewRevision}
				onRequestReviewOfRevision={handleMaybeSendRevisionForReview}
				onDiscardRevision={handleMaybeDiscardRevision}
			/>
		</Flex>
	);

	return (
		<Flex
			paddingTop={["0.8em", "1.5em"]}
			paddingLeft="1.5em"
			paddingRight="1.5em"
			paddingBottom="1.5em"
			width={"100%"}
			height={"100%"}
			direction="column"
			alignItems="center"
		>
			{/* Modal Views */}
			{uiStateIsActiveModalVisible ? uiStateActiveModalItem : null}
			{/* name and tools bar */}
			{uiStateIsLargeScreen ? (
				<ToolbarAndNameLargeScreenView />
			) : (
				<ToolbarAndNameMobileScreenView />
			)}

			<Box
				width="100%"
				height="95%"
				maxHeight={["80%", "95%"]}
				display="flex"
				flexDirection="row"
			>
				{/* Comment Legend Margin */}
				{uiStateIsLargeScreen ? editorLargeScreenView : editorMobileView}
			</Box>
			<Box marginTop="1em">
				<RevisionPaginationView
					currentRevision={docStatePost.revision}
					totalRevisions={docStatePost.latestRevision}
					onRevisionSelected={(revision) => handleOnRevisionSelected(revision)}
				/>
			</Box>
		</Flex>
	);
};
