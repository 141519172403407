const ENV_TYPE = {
	dev: "development",
	prod: "production",
};
// For Production deployment disable logging.
export const LogBasic = (key: string, value: any = undefined) => {
	if (process.env.NODE_ENV === ENV_TYPE.dev) {
		if (value) {
			console.log(key, value);
		} else {
			console.log(key);
		}
	}
};

export const LogInfo = (key: string, value: any = undefined) => {
	LogBasic(`[Info::] ${key}`, value);
};

export const LogError = (key: string, value: any = undefined) => {
	LogBasic(`[Error::] ${key}`, value);
};

export const AsPixelStr = (value: number) => {
	return `${value}px`;
};

export const GetFirstCharacterFromEmail = (email: string | null) => {
	const character = email && email.length > 0 ? email[0] : "";
	return character;
};

export const GetUsernameFromEmail = (email: string | null) => {
	return email && email.length > 0 ? email.split("@")[0] : "";
};
