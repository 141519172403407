import { Box, Button, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../../services/route/route_paths";
import { GetFirstCharacterFromEmail, LogInfo } from "../../util/util";
import { useAuth } from "../components/auth-context";
import { ProfileAvatar } from "../components/profile-avatar";

interface ProfileSettingsViewProps {}

export const ProfileSettingsView = (props: ProfileSettingsViewProps) => {
	const { userCredential, signUserOut } = useAuth();
	const history = useHistory();

	const handleSignOut = () => {
		signUserOut()
			.then((res) => {
				history.push(RoutePaths.codeSnippetListRoute());
			})
			.catch((err) => {
				LogInfo("Error while trying to sign out");
			});
	};
	return (
		<Box>
			<VStack spacing="20px">
				<ProfileAvatar
					size="5.2em"
					fontSize="3em"
					character={GetFirstCharacterFromEmail(
						userCredential.user?.email || ""
					)}
				/>
				<Text>{userCredential.user?.email || ""}</Text>
				<Button
					onClick={() => {
						handleSignOut();
					}}
					variant="outline"
				>
					Sign Out
				</Button>
			</VStack>
		</Box>
	);
};
