import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { ThemeAppearance } from "../../appearance/appearance";

interface TextUnderlineViewProps {
	text: string;
	isActive: boolean;
}
export const TextUnderlineView = (props: TextUnderlineViewProps) => {
	return (
		<Box display="flex" flexDirection="column">
			<Text fontSize={"sm"} fontWeight="semibold">
				{props.text}
			</Text>
			<Box
				height="2px"
				background={props.isActive ? ThemeAppearance.primaryColor : undefined}
			></Box>
		</Box>
	);
};
