import { Circle, Text } from "@chakra-ui/react";
import React from "react";
import { ThemeAppearance } from "../../appearance/appearance";

interface ProfileAvatarProps {
	character: string;
	size: string;
	fontSize: string;
}
export const ProfileAvatar = (props: ProfileAvatarProps) => {
	return (
		<Circle
			// background="blue.500"
			background={ThemeAppearance.primaryColor}
			size={props.size}
			color="white"
		>
			<Text fontWeight="medium" fontSize={props.fontSize}>
				{props.character}
			</Text>
		</Circle>
	);
};
