import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, Text } from "@chakra-ui/react";
import React from "react";

interface RevisionPaginationViewProps {
	currentRevision: number;
	totalRevisions: number;
	onRevisionSelected: (revision: number) => void;
}
export const RevisionPaginationView = (props: RevisionPaginationViewProps) => {
	const isLeftActive = props.currentRevision > 1 ? true : false;
	const isRightActive =
		props.currentRevision < props.totalRevisions ? true : false;

	const handleRightButtonSelected = () => {
		if (props.currentRevision + 1 <= props.totalRevisions) {
			props.onRevisionSelected(props.currentRevision + 1);
		}
	};
	const handleLeftButtonSelected = () => {
		if (props.currentRevision - 1 >= 1) {
			props.onRevisionSelected(props.currentRevision - 1);
		}
	};
	return (
		<Box>
			<HStack>
				<IconButton
					size="sm"
					aria-label={"Previous revision"}
					variant="outline"
					onClick={() => {
						handleLeftButtonSelected();
					}}
					isDisabled={!isLeftActive}
				>
					<ArrowLeftIcon />
				</IconButton>

				<Text>
					{" "}
					{props.currentRevision} of {props.totalRevisions}{" "}
				</Text>
				<IconButton
					variant="outline"
					size="sm"
					aria-label={"Next revision"}
					onClick={() => {
						handleRightButtonSelected();
					}}
					isDisabled={!isRightActive}
				>
					<ArrowRightIcon />
				</IconButton>
			</HStack>
		</Box>
	);
};
