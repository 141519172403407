import {
	Box,
	Flex,
	Heading,
	HStack,
	LinkBox,
	LinkOverlay,
	Select,
	SimpleGrid,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ThemeAppearance } from "../appearance/appearance";
import { CodeEditorView } from "../view-components/code-editor/code-editor-view";
import { PageLayout } from "../view-components/components/page-layout";
import { CodeSnippet } from "../models/models";
import { RoutePaths } from "../services/route/route_paths";
import { BasicBackendCodeSnippetApiService } from "../services/backend/code-snippet-api-service";
import { LogError } from "../util/util";
import { useAuth } from "../view-components/components/auth-context";
import { SpinnerBackdrop } from "../view-components/components/spinner-backdrop";
import { LogInfo } from "../util/util";
import {
	FilterSnippetListService,
	SortSnippetListService,
} from "../services/lib/sort-filter-snippets";

interface CodeSnippetMiniViewProps {
	post: CodeSnippet;
	detaiLink: string;
}

const CodeSnippetMiniView = ({ post, detaiLink }: CodeSnippetMiniViewProps) => {
	return (
		<LinkBox
			as="article"
			background={ThemeAppearance.cardBackground}
			padding="2em"
			rounded={"10px"}
			shadow="md"
			borderWidth="1px"
		>
			<LinkOverlay href={`${detaiLink}`}>
				<Box>
					<Heading fontSize="l" marginBottom="20px">
						{post.title}
					</Heading>
					<Box height="100px">
						<CodeEditorView
							readOnly={true}
							interactionDisabled
							value={post.codeText}
							languageMode={post.languageMode}
							onCursorPositionChange={(value) => {}}
							onTextChange={() => {}}
							name={`${post.snippetId}-div`}
							width="100%"
							height="100%"
						/>
						{/* <Box position="relative" top="-100px" height="100px"></Box> */}
					</Box>

					{/* </Box> */}
					<Flex direction="row" justifyContent="center" marginTop="10px">
						<Text fontSize="xs" fontStyle="italic">
							{`${post.dateTime.toDateString()} (${post.dateTime.getHours()}:${post.dateTime.getMinutes()})`}
						</Text>
						<Spacer />
						<Text fontSize="xs">revision {post.revision}</Text>
					</Flex>
				</Box>
			</LinkOverlay>
		</LinkBox>
	);
};

export const HomeView = () => {
	const defaultPosts: CodeSnippet[] = [];
	const [docStateOriginalPosts, setDocStateOriginalPosts] = useState(
		defaultPosts
	);
	const [posts, setPosts] = useState(defaultPosts);
	const [isFetchingPosts, setIsFetchingPosts] = useState(true);
	// const defaultFilterSortTransform: FilterAndSortTransform = {filterBy: "all", sortBy: "date"};
	const [uiStateFilterBy, setUiStateFilterBy] = useState("all");
	const [uiStateSortBy, setUiStateSortBy] = useState("date");

	const { userCredential } = useAuth();

	useEffect(() => {
		setIsFetchingPosts(true);
		new BasicBackendCodeSnippetApiService(userCredential)
			.getSnippets()
			.then((res) => {
				setPosts(res);
				setDocStateOriginalPosts(res);
				setIsFetchingPosts(false);
			})
			.catch((err) => {
				LogError("error fetching posts");
				setPosts([]);
				setIsFetchingPosts(false);
			});
	}, [userCredential]);
	const postsGridView = (
		<SimpleGrid
			columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
			spacing={["15px", "40px"]}
			paddingBottom={["10px"]}
		>
			{posts.map((post) => (
				<CodeSnippetMiniView
					key={post.snippetId}
					post={post}
					detaiLink={RoutePaths.codeSnippetDetailWithRevisionRoute(
						post.snippetId,
						post.revision
					)}
				/>
			))}
		</SimpleGrid>
	);

	const handleFilterAndSortSnippets = (filterBy: string, sortBy: string) => {
		LogInfo("Filtering By", filterBy);
		const filterdList = new FilterSnippetListService().filterBy(
			docStateOriginalPosts,
			filterBy
		);
		// sort
		const sortedFilteredList = new SortSnippetListService().sortBy(
			filterdList,
			sortBy
		);
		setPosts(sortedFilteredList);
		setUiStateFilterBy(filterBy);
		setUiStateSortBy(sortBy);
	};

	return (
		<PageLayout locName="snippets">
			<Box
				display="flex"
				flexDirection="column"
				padding={["20px", "40px"]}
				height="100%"
				// background="orange"
				overflowY="auto"
				// marginBottom="20px"
			>
				{/* Top Margin */}
				<Flex
					marginTop="1em"
					direction="column"
					height="100%"

					// background="green"
				>
					<SimpleGrid
						columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
						spacing={["15px", "40px"]}
						paddingBottom={["10px"]}
						marginBottom="1.2em"
					>
						<Heading fontSize={["xl", "3xl"]}>Your Snippets</Heading>
						{/* <Spacer /> */}
						<HStack width="100%">
							<Spacer />
							<Select
								maxWidth="200px"
								placeholder="Sort by"
								value={uiStateSortBy}
								onChange={(e) =>
									handleFilterAndSortSnippets(uiStateFilterBy, e.target.value)
								}
							>
								<option value={SortSnippetListService.byDate}>
									SortBy: date
								</option>
								<option value={SortSnippetListService.byName}>
									SortBy: name
								</option>
							</Select>
							<Select
								maxWidth="200px"
								placeholder="Filter by"
								// defaultValue={FilterSnippetListService.all}
								value={uiStateFilterBy}
								onChange={(e) => {
									console.log(e);
									handleFilterAndSortSnippets(e.target.value, uiStateSortBy);
								}}
							>
								<option value={FilterSnippetListService.all}>
									Filter: All
								</option>
								<option value={FilterSnippetListService.reviewed}>
									Filter: Reviewed
								</option>
								<option value={FilterSnippetListService.pending}>
									Filter: Pending
								</option>
							</Select>
						</HStack>
					</SimpleGrid>

					{isFetchingPosts ? (
						<Flex
							direction="column"
							height="80%"
							justifyContent="flex-start"
							alignItems="center"
						>
							<SpinnerBackdrop shortDescription="Fetching snippets" />
						</Flex>
					) : (
						postsGridView
					)}
				</Flex>
			</Box>
		</PageLayout>
	);
};
