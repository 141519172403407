import { Tooltip, Button } from "@chakra-ui/react";
import React from "react";

interface ToolTipButtonProps {
	toolTipLabel: string;
	icon?: any;
	onClick: () => void;
	children?: any;
	[rest: string]: any;
}

export const ToolTipButton = (props: ToolTipButtonProps) => {
	return (
		<Tooltip hasArrow label={props.toolTipLabel} bg="blue.600">
			<Button
				{...props.rest}
				size="sm"
				leftIcon={props.icon}
				onClick={() => {
					props.onClick();
				}}
				variant="outline"
			>
				{props.children}
			</Button>
		</Tooltip>
	);
};
