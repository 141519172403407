import {
	CodeReviewComment,
	PendingCodeReviewComment,
} from "../../models/models";
import { LogInfo } from "../../util/util";
import { AuthUserCredential } from "./auth-service";
import { FirebaseFunctionsService } from "./firebase-service";

interface CMCodeReviewComment {
	commentId: string;
	// author of comment
	authorName: string;
	// comment info
	lineStart: number;
	lineStop: number;
	reviewText: string;
	// Date and time
	timestampMilli: number;
}

export interface AddReviewsToSnippetRequest {
	snippetId: string;
	revision: number;
	reviews: PendingCodeReviewComment[];
}

interface AddReviewsToSnippetResponse {
	reviews?: CMCodeReviewComment[];
}

export interface GetSnippetReviewsRequest {
	snippetId: string;
	revision: number;
}

interface GetSnippetReviewsResponse {
	reviews?: CMCodeReviewComment[];
}

export interface BackendCodeReviewCommentApiService {
	getSnippetReviews(
		request: GetSnippetReviewsRequest
	): Promise<CodeReviewComment[]>;
	addReviewsToSnippet(
		request: AddReviewsToSnippetRequest
	): Promise<CodeReviewComment[]>;
}

export class BasicCodeReviewCommentApiService
	implements BackendCodeReviewCommentApiService {
	userCredential: AuthUserCredential;

	constructor(userCredential: AuthUserCredential) {
		this.userCredential = userCredential;
	}

	getSnippetReviews(
		request: GetSnippetReviewsRequest
	): Promise<CodeReviewComment[]> {
		if (request.snippetId.trim().length <= 0) {
			return Promise.resolve([]);
		}
		return new Promise((resolve, reject) => {
			const GetSnippetReviewsCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetReviewCommentService_GetSnippetReviews"
			);
			LogInfo("Sending GetSnippetReviewsRequest to server", request);
			GetSnippetReviewsCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetSnippetReviewsResponse;
					const responseData = response.reviews;
					if (responseData) {
						const reviews: CodeReviewComment[] = [];
						responseData.forEach((review) => {
							reviews.push({
								commentId: review.commentId,
								// author of comment
								authorName: review.authorName,
								// comment info
								lineStart: review.lineStart,
								lineStop: review.lineStop,
								reviewText: review.reviewText,
								// Date and time
								dateTime: new Date(review.timestampMilli), // DD-MM-YY hh:mm AM|PM
							});
						});

						LogInfo("retrieved GetSnippetReviewsResponse snippet", reviews);
						resolve(reviews);
					} else {
						reject("Could not retrieve Snippet reviews");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	addReviewsToSnippet(
		request: AddReviewsToSnippetRequest
	): Promise<CodeReviewComment[]> {
		// Call To Database
		return new Promise((resolve, reject) => {
			const AddReviewsToSnippetCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetReviewCommentService_AddReviewsToSnippet"
			);
			LogInfo("Sending AddReviewsToSnippetRequest to server");
			AddReviewsToSnippetCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as AddReviewsToSnippetResponse;
					const responseData = response.reviews;
					if (responseData) {
						const reviews: CodeReviewComment[] = [];
						responseData.forEach((review) => {
							reviews.push({
								commentId: review.commentId,
								// author of comment
								authorName: review.authorName,
								// comment info
								lineStart: review.lineStart,
								lineStop: review.lineStop,
								reviewText: review.reviewText,
								// Date and time
								dateTime: new Date(review.timestampMilli), // DD-MM-YY hh:mm AM|PM
							});
						});

						LogInfo("retrieved AddReviewsToSnippetResponse snippet", reviews);
						resolve(reviews);
					} else {
						reject("Could not add reviews to snippet");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
