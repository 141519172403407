import { FirebaseServiceApp } from "./firebase-service";
import firebase from "firebase/app";
import { LogInfo } from "../../util/util";

export interface AuthUserCredential {
	user: firebase.User | null;
}

export interface OnAuthStateChanged {
	(user: AuthUserCredential): void;
}

export interface AuthUnsubscribe {
	(): void;
}

export interface AuthService {
	onAuthStateChanged(callback: OnAuthStateChanged): AuthUnsubscribe;
}

export class BasicAuthService implements AuthService {
	onAuthStateChanged(callback: OnAuthStateChanged) {
		const unsubscribe = FirebaseServiceApp.auth().onAuthStateChanged((user) => {
			LogInfo("Call auth change");
			const userCredential: AuthUserCredential = { user: user };
			callback(userCredential);
		});
		const unsubscribeWrapper = () => {
			unsubscribe();
		};
		return unsubscribeWrapper;
	}
}
