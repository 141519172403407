import { CodeSnippet } from "../../models/models";

interface FilterItemTransform {
	(item: any): boolean;
}

interface SortItemTransform {
	(item: any): any;
}

export class FilterService {
	transform: FilterItemTransform;
	constructor(transform: FilterItemTransform) {
		this.transform = transform;
	}

	filter(snippetList: any[]) {
		const newList: any[] = [];
		snippetList.forEach((item) => {
			if (this.transform(item)) {
				newList.push(item);
			}
		});
		return newList;
	}
}

export class SortService {
	transform: SortItemTransform;
	constructor(transform: SortItemTransform) {
		this.transform = transform;
	}

	sort(snippetList: any[], isAscending: boolean = true) {
		const newList: any[] = [...snippetList];
		newList.sort((itemA, itemB) => {
			const transformA = this.transform(itemA);
			const transformB = this.transform(itemB);
			if (transformA < transformB) {
				return isAscending ? -1 : 1;
			} else if (transformA > transformB) {
				return isAscending ? 1 : -1;
			} else {
				return 0;
			}
		});
		return newList;
	}
}

export class FilterSnippetListService {
	static reviewed: string = "reviewed";
	static all: string = "all";
	static pending: string = "pending";

	filterBy(snippetList: any[], filterKey: string) {
		if (filterKey === FilterSnippetListService.reviewed) {
			return new FilterService((item: any) => {
				const snippet: CodeSnippet = item as CodeSnippet;
				return snippet.hasBeenReviewed;
			}).filter(snippetList);
		} else if (filterKey === FilterSnippetListService.pending) {
			return new FilterService((item: any) => {
				const snippet: CodeSnippet = item as CodeSnippet;
				return !snippet.hasBeenReviewed;
			}).filter(snippetList);
		} else {
			return [...snippetList];
		}
	}
}

export class SortSnippetListService {
	static byName: string = "name";
	static byDate: string = "date";

	sortBy(snippetList: any[], sortBy: string) {
		const newList: any[] = [...snippetList];
		if (sortBy === SortSnippetListService.byDate) {
			return new SortService((item: any) => {
				const snippet: CodeSnippet = item as CodeSnippet;
				return snippet.dateTime.getMilliseconds();
			}).sort(snippetList);
		} else if (SortSnippetListService.name) {
			return new SortService((item: any) => {
				const snippet: CodeSnippet = item as CodeSnippet;
				return snippet.title.toLocaleLowerCase();
			}).sort(snippetList);
		}
		return [...newList];
	}
}
