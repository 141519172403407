import {
	Box,
	Button,
	HStack,
	Input,
	Select,
	SimpleGrid,
	Spacer,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

import { CodeEditorView } from "../view-components/code-editor/code-editor-view";
import { PageLayout } from "../view-components/components/page-layout";
import { LanguageMode } from "../models/language-modes";
import { RoutePaths } from "../services/route/route_paths";
import {
	BasicBackendCodeSnippetApiService,
	CreateCodeSnippetRequest,
} from "../services/backend/code-snippet-api-service";

import { LogError } from "../util/util";
import { useAuth } from "../view-components/components/auth-context";
import { ThemeAppearance } from "../appearance/appearance";

const DefaultCodeText = `print("Paste your snippet here")
`;

export const CreateCodeSnippetView = () => {
	const [codeTextState, setCodeTextState] = React.useState(DefaultCodeText);
	const [languageModeState, setLanguageModeState] = React.useState(
		LanguageMode.language.python
	);
	const [titleState, setTitleState] = React.useState("");
	const [isPostingState, setIsPostingState] = React.useState(false);
	const history = useHistory();
	const { userCredential } = useAuth();

	const handleCodeTextChange = (value: string) => {
		setCodeTextState(value);
	};
	const handleTitleChange = (title: string) => {
		setTitleState(title);
	};
	const handleLanguageModeChange = (mode: string) => {
		setLanguageModeState(mode);
	};
	const doCreateSnippet = () => {
		setIsPostingState(true);
		const request: CreateCodeSnippetRequest = {
			codeText: codeTextState,
			title: titleState,
			languageMode: languageModeState,
		};
		new BasicBackendCodeSnippetApiService(userCredential)
			.createSnippet(request)
			.then((res) => {
				setIsPostingState(false);
				// navigate to detail view
				history.push(RoutePaths.codeSnippetDetailRoute(res.snippetId));
			})
			.catch((err) => {
				LogError("Error occured while posting code");
				setIsPostingState(false);
			});
	};

	const handleRequestReview = () => {
		// Validate the request.

		// send post
		doCreateSnippet();
	};

	return (
		<PageLayout>
			<Box
				padding="20px"
				// background="orange"
				height="100%"
			>
				<Box
					// background="tomato"
					height="100%"
					display="flex"
					flexDirection="column"
				>
					{/* <Flex direction="column"> */}
					<SimpleGrid
						// background="orange"
						columns={{ sm: 1, md: 2 }}
						spacing={["8px", "40px"]}
						paddingBottom={["10px"]}
					>
						<Input
							fontSize={["xs", "md"]}
							placeholder={`Snippet Title e.g "isUnique Runs"`}
							onChange={(e) => {
								handleTitleChange(e.target.value);
							}}
							value={titleState}
							maxWidth="400px"
							variant="flushed"
						/>

						{/* <Spacer /> */}
						<HStack>
							<Spacer />
							<Box>
								<Select
									fontSize={["sm", "lg"]}
									value={languageModeState}
									onChange={(e) => {
										handleLanguageModeChange(e.target.value);
									}}
								>
									{LanguageMode.all().map((mode) => (
										<option value={mode} key={mode}>
											{LanguageMode.getModeDisplayName(mode)}
										</option>
									))}
								</Select>
							</Box>

							{/* <Button
								size="md"
								onClick={() => handleCancelPost()}
								disabled={isPostingState}
								variant="outline"
							>
								Cancel
							</Button> */}
							<Button
								// colorScheme="green"
								// fontSize={["sm", "lg"]}
								size="md"
								borderColor={ThemeAppearance.callToAction.button}
								color={ThemeAppearance.callToAction.button}
								// colorScheme={ThemeAppearance.primaryMainColor}
								onClick={() => handleRequestReview()}
								isLoading={isPostingState}
								loadingText="Requesting..."
								variant="outline"
							>
								Request Review
							</Button>
						</HStack>
					</SimpleGrid>
					{/* <HStack marginBottom="20px">
					
					</HStack> */}

					<Box
						// background="green"
						// width="100%"
						height="100%"
					>
						<CodeEditorView
							readOnly={false}
							value={codeTextState}
							languageMode={languageModeState}
							onTextChange={handleCodeTextChange}
							onCursorPositionChange={() => {}}
							name="code-editor-div"
							width="100%"
							height="100%"
						/>
					</Box>

					{/* </Flex> */}
				</Box>
			</Box>
		</PageLayout>
	);
};
