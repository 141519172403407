import {
	Modal,
	Text,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

interface RevisionReviewRequestModalViewProps {
	isVisible: boolean;
	onCancel: () => void;
	onSend: (notes: string) => void;
}

export const RevisionRequestModalView = (
	props: RevisionReviewRequestModalViewProps
) => {
	const [noteText, setNoteText] = useState("");

	return (
		<Modal isOpen={props.isVisible} onClose={props.onCancel}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Send Revision for Review</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Notes</Text>
					<Textarea
						marginTop="5px"
						value={noteText}
						minHeight="100px"
						onChange={(event) => {
							setNoteText(event.target.value);
						}}
					/>
				</ModalBody>

				<ModalFooter>
					<Button mr={3} onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						colorScheme="blue"
						variant="ghost"
						onClick={() => props.onSend(noteText)}
					>
						Send
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
