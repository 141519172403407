import { Box, Center, Flex, Text, CircularProgress } from "@chakra-ui/react";
import React from "react";

interface SpinnerBackdropProps {
	shortDescription: string;
}
export const SpinnerBackdrop = (props: SpinnerBackdropProps) => {
	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			<Center>
				<Flex
					flexDirection="column"
					justifyContent="Center"
					alignItems="center"
				>
					<CircularProgress
						isIndeterminate
						color="green.300"
						size="100px"
						thickness="12px"
					/>
					<Text marginTop="10px">{props.shortDescription}</Text>
				</Flex>
			</Center>
		</Box>
	);
};
