import { Box } from "@chakra-ui/react";
import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-swift";
import "ace-builds/src-noconflict/mode-c_cpp";
import "./code-editor-view.css";

import "ace-builds/src-noconflict/theme-github";
import { LanguageMode } from "../../models/language-modes";
import { LogInfo } from "../../util/util";

const LanguageModeMap = {
	[LanguageMode.language.python]: "python",
	// [LanguageMode.language.java]: "java",
	// [LanguageMode.language.cpp]: "c_cpp",
	// [LanguageMode.language.swift]: "swift",
	// [LanguageMode.language.text]: "text",
};

const RowGridToLineNumber = (value: number) => {
	return value + 1;
};
// const LineToRowGridNumber = (value: number) => {
// 	return value - 1;
// };
const ColumnGridToColumnNumber = (value: number) => {
	return value + 1;
};
// const ColumnToColumnGridNumber = (value: number) => {
// 	return value - 1;
// };

/**
 * Current Location of the Cursor on the Editor.
 * Both line and column are 1 index based.
 */
export interface CursorPosition {
	line: number; // Line Number of the cursor. 1 index based.
	column: number; // Column Number. 1 index based.
}

export interface LineHighlight {
	lineStart: number;
	lineStop: number;
}

export interface CodeEditorViewProps {
	readOnly: boolean; // allows for interaction
	interactionDisabled?: boolean;
	value: string;
	name: string;
	width: string;
	height: string;
	languageMode: string; // programing language
	onCursorPositionChange: (value: CursorPosition) => void;
	onTextChange: (value: string) => void;
	children?: any;
	lineHighlight?: LineHighlight;
}

export class CodeEditorView extends React.Component<CodeEditorViewProps, any> {
	// const handleCodeTextChange = (e: string) => {
	// 	console.log(codeText);
	// };

	render() {
		const props = this.props;
		let markers: any[] = [];
		if (props.lineHighlight) {
			markers.push({
				startRow: props.lineHighlight.lineStart - 1,
				startCol: 0,
				endRow: props.lineHighlight.lineStop - 1,
				endCol: 200,
				className: "line-highlight",
				type: "text",
			});
		}

		return (
			<Box width={props.width} height={props.height} borderWidth="1px">
				<Box width={props.width} height={props.height}>
					<AceEditor
						markers={markers}
						readOnly={props.readOnly}
						onSelection={() => {
							LogInfo("Selection Occured");
						}}
						onSelectionChange={(value, event) => {
							LogInfo("Selection Changed", value);
							LogInfo("Selection Event", event);
						}}
						onCursorChange={(value) => {
							//
							const pos = value.getCursor();
							const cursorPos: CursorPosition = {
								line: RowGridToLineNumber(pos.row), // Note:
								column: ColumnGridToColumnNumber(pos.column),
							};
							LogInfo("Cursor Change In Grid Coordinate", value.getCursor());
							LogInfo("Cursor New Position (Line Coordinate) ", cursorPos);

							props.onCursorPositionChange(cursorPos);
						}}
						// annotations={annotations}
						value={props.value}
						mode={
							LanguageModeMap[props.languageMode]
								? LanguageModeMap[props.languageMode]
								: "text"
						}
						theme="github"
						onChange={(value) => {
							props.onTextChange(value);
						}}
						name="code-editor-div"
						width="100%"
						height="100%"
					>
						{props.children}
					</AceEditor>
					{/* Disable Interaction.  */}
					{props.interactionDisabled ? (
						<Box
							position="relative"
							top={`-${props.height}`}
							height={props.height}
						></Box>
					) : null}
				</Box>
			</Box>
		);
	}
}
