import {
	Box,
	Button,
	Flex,
	Heading,
	LinkBox,
	LinkOverlay,
	SimpleGrid,
	Spacer,
	Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ThemeAppearance } from "../../appearance/appearance";
import { CRCommentNotification } from "../../models/models";
import { BasicBackendNotificationApiService } from "../../services/backend/notifcation-api-service";
import { RoutePaths } from "../../services/route/route_paths";
import { LogError, LogInfo } from "../../util/util";
import { useAuth } from "../components/auth-context";
import { SpinnerBackdrop } from "../components/spinner-backdrop";

interface NotificationItemViewProps {
	notification: CRCommentNotification;
	linkTo: string;
	onSelect: () => void;
}

const NotificationItemView = (props: NotificationItemViewProps) => {
	const [isLoading, setIsLoading] = useState(false);
	return (
		<LinkBox
			as="article"
			background={ThemeAppearance.cardBackground}
			padding="1.5em"
			rounded={"10px"}
			shadow="md"
			borderWidth="1px"
			// maxWidth="400px"
			// minWidth="500px"
			// maxHeight="200px"
		>
			<LinkOverlay>
				<Box>
					<Heading fontSize="sm" marginBottom="20px">
						{props.notification.description}
					</Heading>
					<Text fontSize="sm">Snippet: {props.notification.snippetTitle}</Text>

					{/* </Box> */}
					<Flex
						direction="row"
						justifyContent="center"
						marginTop="20px"
						alignItems="center"
					>
						<Text fontSize="xs" fontStyle="italic">
							{/* {props.notification} */}
							{`${props.notification.activityCreatedDateTime.toLocaleDateString()} ${props.notification.activityCreatedDateTime.toLocaleTimeString()}`}
						</Text>
						<Spacer />
						<Button
							variant="outline"
							colorScheme="blue"
							size="sm"
							onClick={() => {
								setIsLoading(true);
								props.onSelect();
							}}
							isLoading={isLoading}
						>
							Go to snippet
						</Button>
					</Flex>
				</Box>
			</LinkOverlay>
		</LinkBox>
	);
};

interface NotificationsViewProps {}

export const NotificationsView = (props: NotificationsViewProps) => {
	const defaultList: CRCommentNotification[] = [];
	const [notifications, setNotifications] = useState(defaultList);
	// const [
	// 	uiStateNetworkIsMarkingAsRead,
	// 	setUiStateNetworkIsMarkingAsRead,
	// ] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { userCredential } = useAuth();
	const history = useHistory();

	useEffect(() => {
		new BasicBackendNotificationApiService(userCredential)
			.getNotifications()
			.then((res) => {
				setNotifications(res);
				setIsLoading(false);
			})
			.catch((err) => {
				LogError("Error While Fetching Notifications");
			});
	}, [userCredential]);
	const handleOnSelectNotification = async (
		notification: CRCommentNotification
	) => {
		// setUiStateNetworkIsMarkingAsRead(true);
		try {
			await new BasicBackendNotificationApiService(
				userCredential
			).markNotificationAsRead(notification.notificationId);
		} catch {
			LogInfo("error occured while marking notificatoin");
			return;
		}
		// navigate to route.
		history.push(
			RoutePaths.codeSnippetDetailWithRevisionRoute(
				notification.snippetId,
				notification.revision
			)
		);
	};
	const notificationsGridView = (
		<SimpleGrid columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} spacing="10px">
			{notifications.map((notification) => (
				<NotificationItemView
					onSelect={() => {
						handleOnSelectNotification(notification);
					}}
					linkTo={RoutePaths.codeSnippetDetailRoute(notification.snippetId)}
					key={notification.notificationId}
					notification={notification}
				/>
			))}
		</SimpleGrid>
	);
	return (
		<Box
			display="flex"
			flexDirection="column"
			background="white"
			width="100%"
			// height="80%"
			height="100%"
			padding="5px"
			// overflowY="auto"
		>
			{/* Top Margin */}

			{isLoading ? (
				<Flex
					direction="column"
					// marginTop="0.5em"
					marginTop="50%"
					justifyContent="center"
					alignItems="center"
					// background="orange"
					// height="50%"
				>
					<SpinnerBackdrop shortDescription="fetching notifications" />
				</Flex>
			) : (
				<Box marginTop="1em">{notificationsGridView}</Box>
			)}
		</Box>
	);
};
