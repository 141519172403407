import { CodeSnippet } from "../../models/models";
import { LogInfo } from "../../util/util";
import { AuthUserCredential } from "./auth-service";
import { FirebaseFunctionsService } from "./firebase-service";

interface CMCodeSnippet {
	isAuthor: boolean;
	hasBeenReviewed: boolean;
	snippetId: string;
	authorName: string;
	title: string;
	languageMode: string;
	codeText: string;
	// Revision
	revision: number; // revision number
	latestRevision: number; // latest Revision
	timestampMilli: number; // time stamp in milliseconds
}

/**
 *
 */
interface CreateCodeSnippetResponse {
	snippet: CMCodeSnippet;
}
export interface CreateCodeSnippetRequest {
	codeText: string;
	languageMode: string;
	title: string;
}

/**
 *
 */
interface GetSnippetsResponse {
	snippets: CMCodeSnippet[];
}

/**
 *
 */
export interface CreateCodeRevisionRequest {
	currentRevision: number;
	snippetId: string;
	codeText: string;
	notes: string;
}
interface CreateCodeRevisionResponse {
	snippet?: CMCodeSnippet;
}

/**
 * GetSnippetDetailRequest
 */
export interface GetSnippetDetailRequest {
	snippetId: string;
	revision?: number;
}
interface GetSnippetDetailResponse {
	snippet?: CMCodeSnippet;
}

/**
 *
 */
interface DeleteCodeSnippetRequest {
	snippetId: string;
}
interface DeleteCodeSnippetResponse {
	status: boolean;
}

/**
 *
 */
export interface BackendCodeSnippetApiService {
	getSnippets(): Promise<CodeSnippet[]>;
	getSnippetDetail(request: GetSnippetDetailRequest): Promise<CodeSnippet>;
	// getSnippetDetailForRevision(snippetId: string, revision: number): Promise<CodeSnippet>;
	createSnippet(post: CreateCodeSnippetRequest): Promise<CodeSnippet>;
	deleteSnippet(snippetId: string): Promise<boolean>;
	createRevision(request: CreateCodeRevisionRequest): Promise<CodeSnippet>;
}

export class BasicBackendCodeSnippetApiService
	implements BackendCodeSnippetApiService {
	// user credential
	userCredential: AuthUserCredential;

	constructor(userCredential: AuthUserCredential) {
		this.userCredential = userCredential;
	}
	deleteSnippet(snippetId: string): Promise<boolean> {
		// find post and delete
		const request: DeleteCodeSnippetRequest = { snippetId: snippetId };
		return new Promise((resolve, reject) => {
			const DeleteCodeSnippetCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetService_DeleteCodeSnippet"
			);
			LogInfo("Sending request to server");
			DeleteCodeSnippetCallable(request)
				.then((result) => {
					// Read result of cloud function.

					const response = result.data as DeleteCodeSnippetResponse;
					if (response.status) {
						resolve(response.status);
					} else {
						reject("Error Occured while trying to delete posts");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	getSnippets(): Promise<CodeSnippet[]> {
		return new Promise((resolve, reject) => {
			const GetSnippetsCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetService_GetSnippets"
			);
			LogInfo("Sending GetSnippets request to server");
			GetSnippetsCallable()
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetSnippetsResponse;
					const responseData = response.snippets;
					const snippetList: CodeSnippet[] = [];
					responseData.forEach((cmSnippet) => {
						snippetList.push({
							isAuthor: cmSnippet.isAuthor,
							hasBeenReviewed: cmSnippet.hasBeenReviewed,
							snippetId: cmSnippet.snippetId,
							authorName: cmSnippet.authorName,
							title: cmSnippet.title,
							languageMode: cmSnippet.languageMode,
							codeText: cmSnippet.codeText,
							// Revision
							revision: cmSnippet.revision, // revision number,
							latestRevision: cmSnippet.latestRevision,
							dateTime: new Date(cmSnippet.timestampMilli),
						});
					});
					LogInfo("retrieved snippet list", snippetList);
					resolve(snippetList);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	getSnippetDetail(request: GetSnippetDetailRequest): Promise<CodeSnippet> {
		return new Promise((resolve, reject) => {
			const GetSnippetCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetService_GetSnippetDetail"
			);
			LogInfo("Sending GetSnippets request to server");
			GetSnippetCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetSnippetDetailResponse;
					// TODO: response might not have the required snippet. Pls fix.
					if (!response.snippet) {
						reject("Snippet not available");
						return;
					}
					const responseData = response.snippet;
					const snippet: CodeSnippet = {
						isAuthor: responseData.isAuthor,
						hasBeenReviewed: responseData.hasBeenReviewed,
						snippetId: responseData.snippetId,
						authorName: responseData.authorName,
						title: responseData.title,
						languageMode: responseData.languageMode,
						codeText: responseData.codeText,
						// Revision
						revision: responseData.revision, // revision number
						latestRevision: responseData.latestRevision,
						dateTime: new Date(responseData.timestampMilli),
					};

					LogInfo("retrieved snippet", responseData);
					resolve(snippet);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	createSnippet(postRequest: CreateCodeSnippetRequest): Promise<CodeSnippet> {
		return new Promise((resolve, reject) => {
			const CreateCodeSnippetCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetService_CreateCodeSnippet"
			);
			LogInfo("Sending request to server");
			CreateCodeSnippetCallable(postRequest)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as CreateCodeSnippetResponse;
					const responseData = response.snippet;
					if (responseData) {
						const snippet: CodeSnippet = {
							isAuthor: responseData.isAuthor,
							hasBeenReviewed: responseData.hasBeenReviewed,
							snippetId: responseData.snippetId,
							authorName: responseData.authorName,
							title: responseData.title,
							languageMode: responseData.languageMode,
							codeText: responseData.codeText,
							// Revision
							revision: responseData.revision, // revision number
							latestRevision: responseData.latestRevision,
							dateTime: new Date(responseData.timestampMilli),
						};
						LogInfo("retrieved snippet", snippet);
						resolve(snippet);
					} else {
						reject("Could not create Snippet");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	createRevision(request: CreateCodeRevisionRequest): Promise<CodeSnippet> {
		// create a mew codeRevision. (still leave the comments. )
		return new Promise((resolve, reject) => {
			const CreateCodeRevisionCallable = FirebaseFunctionsService.httpsCallable(
				"CodeReview_SnippetService_CreateCodeRevision"
			);
			LogInfo("Sending request to server");
			CreateCodeRevisionCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as CreateCodeRevisionResponse;
					const responseData = response.snippet;
					if (responseData) {
						const snippet: CodeSnippet = {
							isAuthor: responseData.isAuthor,
							hasBeenReviewed: responseData.hasBeenReviewed,
							snippetId: responseData.snippetId,
							authorName: responseData.authorName,
							title: responseData.title,
							languageMode: responseData.languageMode,
							codeText: responseData.codeText,
							// Revision
							revision: responseData.revision, // revision number
							latestRevision: responseData.latestRevision,
							dateTime: new Date(responseData.timestampMilli),
						};
						LogInfo("retrieved snippet", snippet);
						resolve(snippet);
					} else {
						reject("Could not create Snippet");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
