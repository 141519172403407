import { Box, VStack, Text } from "@chakra-ui/react";
import React from "react";
import { ThemeAppearance } from "../../appearance/appearance";
import { CodeReviewComment } from "../../models/models";

interface CodeReviewCommentViewProps {
	isActive: boolean;
	comment: CodeReviewComment;
	onSelect: () => void;
}

export const CodeReviewCommentView = (props: CodeReviewCommentViewProps) => {
	const { lineStart, lineStop } = props.comment;
	const lines =
		lineStart === lineStop
			? `line: ${lineStart}`
			: `lines: ${lineStart} - ${lineStop}`;
	return (
		<Box
			rounded="md"
			// background="blue.100"
			background={props.isActive ? ThemeAppearance.cardActiveColor : "gray.100"}
			padding="20px"
			onClick={(event) => {
				props.onSelect();
			}}
			shadow="md"
		>
			<VStack alignItems="start" spacing="5px">
				<Text fontSize="xs" fontWeight="bold">
					{lines}
				</Text>
				<Text>{props.comment.reviewText}</Text>
				<Text fontSize="xs" fontStyle="italic">
					{props.comment.authorName
						? `author: ${props.comment.authorName}`
						: ""}
				</Text>
				{/* Include chat here */}
			</VStack>
		</Box>
	);
};
