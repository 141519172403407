import {
	Box,
	VStack,
	HStack,
	Input,
	Textarea,
	Button,
	Text,
} from "@chakra-ui/react";
import React from "react";
import { ThemeAppearance } from "../../appearance/appearance";
import { PendingCodeReviewComment } from "../../models/models";
import { LogInfo } from "../../util/util";

interface CodeReviewCommentEditViewProps {
	isActive: boolean;
	comment: PendingCodeReviewComment;
	onSelect: () => void;
	onRemove: () => void;
	onUpdate: (value: PendingCodeReviewComment) => void;
}

export const CodeReviewCommentEditView = (
	props: CodeReviewCommentEditViewProps
) => {
	const { lineStart, lineStop } = props.comment;
	const tempComment = { ...props.comment };

	return (
		<Box
			rounded="md"
			// background="blue.100"
			// background={props.isActive ? ThemeAppearance.cardActiveColor : "gray.100"}
			padding="20px"
			onClick={(event) => {
				props.onSelect();
			}}
			shadow="md"
			borderColor={
				props.isActive ? ThemeAppearance.cardActiveColor : "gray.100"
			}
			borderWidth="2px"
		>
			<VStack alignItems="start" spacing="5px">
				<HStack>
					<Text fontSize="xs" fontWeight="bold">
						lines:
					</Text>
					<Input
						onChange={(event) => {
							const value = event.target.value;
							if (value) {
								LogInfo("Her");
								tempComment.lineStart = parseInt(value);
							} else {
								tempComment.lineStart = -1;
							}
							props.onUpdate(tempComment);
						}}
						value={lineStart === -1 ? "" : `${lineStart}`}
					/>
					<Text fontSize="xs" fontWeight="bold">
						-
					</Text>
					<Input
						onChange={(event) => {
							const value = event.target.value;
							if (value) {
								tempComment.lineStop = parseInt(value);
							} else {
								tempComment.lineStop = -1;
							}
							props.onUpdate(tempComment);
						}}
						value={lineStop === -1 ? "" : `${lineStop}`}
					/>
				</HStack>

				<Textarea
					onChange={(event) => {
						tempComment.reviewText = event.target.value;
						props.onUpdate(tempComment);
						// setCurrentReviewText(event.target.value);
					}}
					value={props.comment.reviewText}
					placeholder="Enter Comment here"
					minHeight="100px"
				/>
				<HStack>
					<Button
						onClick={() => {
							// props.onComplete(props.comment);
							props.onRemove();
						}}
						size="sm"
					>
						Remove
					</Button>
				</HStack>
			</VStack>
		</Box>
	);
};
