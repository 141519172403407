import React from "react";
import { useParams } from "react-router-dom";
import { CodeReviewView } from "../view-components/code-editor/code-review-view";
import { PageLayout } from "../view-components/components/page-layout";

interface CodeSnippetDetailViewProps {}

export const CodeSnippetDetailView = (props: CodeSnippetDetailViewProps) => {
	// states

	// get Post Id
	let { snippetId, revision }: any = useParams();

	return (
		<PageLayout>
			<CodeReviewView snippetId={snippetId} revision={revision} />
		</PageLayout>
	);
};
